import { formatCNPJ } from './formatCNPJ';
import { formatCPF } from './formatCPF';

export function formatDocument(document: string): string {
  const newDocument = document.replace(/\D/g, '');

  if (newDocument.length < 14) {
    return formatCPF(newDocument);
  }

  return formatCNPJ(newDocument);
}
