import type { FC } from 'react';

import { IContact } from '../../../../../hooks/useSiteData';
import { maskPhone } from '../../../../../lib/maskPhone';
import { Text } from '../../styles';
import { Section } from '../Section';

export type TContactSection = {
  contact?: IContact;
};

export const Contact: FC<TContactSection> = ({ contact }) => {
  return (
    <Section title="Contato">
      {contact?.phoneNumber && <Text>{maskPhone(contact?.phoneNumber)}</Text>}
      {contact?.email && <Text>{contact?.email}</Text>}
      {contact?.address && (
        <Text>
          {contact?.address?.street}, {contact?.address?.number} -{' '}
          {contact?.address?.neighborhood}, {contact?.address?.city} -{' '}
          {contact?.address?.state}
        </Text>
      )}
    </Section>
  );
};
