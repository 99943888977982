import { FC, useMemo } from 'react';

import { IFooter } from '@pra-vendas-themes/interfaces/IFooter';

import { useSiteSettings } from 'hooks/useSiteSettings';

import { formatDocument } from 'utils/formatDocument';

import { Contact } from './components/Contact';
import { Newsletter } from './components/Newsletter';
import { Pages } from './components/Pages';
import { PaymentsWay } from './components/PaymentsWay';
import { SecuritySeals } from './components/SecuritySeals';
import { SocialMedia } from './components/SocialMedia';
import {
  Wrapper,
  Content,
  TopFooter,
  BottomFooter,
  CreateBy,
  Copyright,
} from './styles';

const Footer: FC<IFooter> = ({
  socialMedias,
  contact,
  createdByLogo,
  newsletter,
  pages,
}) => {
  const { storeSettings, jivoChatSettings } = useSiteSettings();

  const copyrightText = useMemo(() => {
    const currentYear = new Date().getFullYear();

    return `Copyright © ${
      storeSettings?.name ? `${storeSettings?.name} - ` : ''
    }${
      storeSettings?.document
        ? `${formatDocument(storeSettings.document)} - `
        : ''
    }${currentYear}. Todos os direitos reservados.`;
  }, [storeSettings?.name, storeSettings?.document]);

  return (
    <Wrapper>
      <Content>
        {!!Object.keys(newsletter || {})?.length && (
          <Newsletter {...newsletter} />
        )}

        {(pages ||
          contact ||
          socialMedias ||
          storeSettings?.hasPaymentConfiguration) && (
          <TopFooter>
            {pages && <Pages pages={pages} />}
            {contact && <Contact contact={contact} />}
            {socialMedias && <SocialMedia {...socialMedias} />}
            {storeSettings?.hasPaymentConfiguration && <PaymentsWay />}
            <SecuritySeals />
          </TopFooter>
        )}

        <BottomFooter hasChat={!!jivoChatSettings?.code}>
          <Copyright>{copyrightText}</Copyright>

          {createdByLogo && (
            <CreateBy>
              Criado com
              <a
                href="https://pravendas.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <img src={createdByLogo} alt="Pra Vendas" />
              </a>
            </CreateBy>
          )}
        </BottomFooter>
      </Content>
    </Wrapper>
  );
};

export default Footer;
