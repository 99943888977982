import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.nano};

  width: max-content;
  width: 180px;
  min-width: 180px;
  max-width: 180px;

  text-align: center;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: ${(p) => p.theme.font.xSmall};
  text-transform: uppercase;
  margin-bottom: ${(p) => p.theme.spacing.small};
  white-space: nowrap;
`;
