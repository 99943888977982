export const maskPhone = (value = ''): string => {
  if (!value) return value;

  const onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 10) {
    return value.replace(/(\d{1,2})(\d{2,5})(\d{2,5})(.*)/, '($1) $2-$3');
  }

  return onlyNums.replace(
    /(\d{1,2})(\d{1})(\d{1,4})(\d{1,4})(.*)/,
    '($1) $2 $3-$4'
  );
};
