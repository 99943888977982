import { FC } from 'react';

import { useSiteSettings } from 'hooks/useSiteSettings';

import { Section } from '../Section';
import { FlagsContainer } from './styles';

const flags = [
  {
    name: 'Visa',
    imageUrl: '/visa.png',
    containerPadding: 7,
    paymentMethods: ['credit_card'],
  },
  {
    name: 'Mastercard',
    imageUrl: '/mastercard.png',
    containerPadding: 4,
    paymentMethods: ['credit_card'],
  },
  {
    name: 'American Express',
    imageUrl: '/amex.png',
    containerPadding: 4,
    paymentMethods: ['credit_card'],
  },
  {
    name: 'Elo',
    imageUrl: '/elo.png',
    containerPadding: 6,
    paymentMethods: ['credit_card'],
  },
  {
    name: 'Hipercard',
    imageUrl: '/hipercard.png',
    containerPadding: 6,
    paymentMethods: ['credit_card'],
  },
  {
    name: 'Boleto',
    imageUrl: '/boleto.jpeg',
    containerPadding: 4,
    paymentMethods: ['boleto'],
  },
  {
    name: 'Pix',
    imageUrl: '/pix.png',
    containerPadding: 4,
    paymentMethods: ['pix'],
  },
];

export const PaymentsWay: FC = () => {
  const { storeSettings } = useSiteSettings();

  const configuratedFlags = flags.reduce((acc, flag) => {
    const newAcc = acc || [];

    let isFlagConfigurated = false;

    flag.paymentMethods.forEach((item) => {
      const existsInStorePaymentWays =
        storeSettings?.payment?.availableMethods?.some(
          (paymentMethod) => paymentMethod.ref === item
        );

      if (existsInStorePaymentWays) {
        isFlagConfigurated = true;
      }
    });

    if (isFlagConfigurated) {
      newAcc.push(flag);
    }

    return newAcc;
  }, [] as typeof flags);

  if (!configuratedFlags || configuratedFlags.length === 0) return null;

  return (
    <Section title="Formas de Pagamento">
      <FlagsContainer>
        {configuratedFlags.map((flag) => (
          <li
            key={flag.name}
            style={{ padding: flag.containerPadding }}
            data-title={flag.name}
          >
            <img src={flag.imageUrl} alt={flag.name} />
          </li>
        ))}
      </FlagsContainer>
    </Section>
  );
};
