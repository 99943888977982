export const formatCPF = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, '');

  return onlyNums.replace(
    /(\d{1,3})(\d{1,3})(\d{1,3})(\d{1,2})(.*)/,
    '$1.$2.$3-$4'
  );
};
