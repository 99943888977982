import styled from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.textPrimary};
  border-top: 1px solid ${(p) => p.theme.colors.border};
  background-color: ${(p) => p.theme.footer.background};

  flex-direction: column;
  margin-top: auto;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: ${(p) => p.theme.maxWidth};
  padding: 0 ${(p) => p.theme.spacing.xLarge};

  @media (max-width: 960px) {
    padding: 0 ${(p) => p.theme.spacing.medium};
  }
`;

export const TopFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  padding: ${(p) => p.theme.spacing.xLarge} 0;
  gap: ${(p) => p.theme.spacing.xLarge};
`;

export const Text = styled.span`
  font-size: ${(p) => p.theme.font.small};
  line-height: ${(p) => p.theme.spacing.medium};
  color: ${(p) => p.theme.colors.textSecondary};
`;

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-size: ${(p) => p.theme.font.small};
  line-height: ${(p) => p.theme.spacing.medium};
  color: ${(p) => p.theme.colors.textSecondary};
`;

interface BottomFooterProps {
  hasChat: boolean;
}

export const BottomFooter = styled.div<BottomFooterProps>`
  padding: ${(p) => p.theme.spacing.xLarge} 0;
  padding-bottom: ${(p) =>
    p.hasChat
      ? `calc(40px + ${p.theme.spacing.xLarge})`
      : p.theme.spacing.xLarge};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${(p) => p.theme.spacing.medium};
  font-size: ${(p) => p.theme.font.small};
  color: ${(p) => p.theme.colors.textSecondary};

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding-bottom: ${({ theme }) => theme.spacing.xLarge} !important;
  }
`;

export const CreateBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.small};

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 0.4s;
    will-change: opacity;

    &:hover {
      opacity: 0.7;
    }
  }

  img {
    height: 15px;
  }
`;

export const Copyright = styled.div`
  text-align: center;
`;
