import type { FC } from 'react';

import { Section } from '../Section';
import { WrapperSeals } from './styles';

export const SecuritySeals: FC = () => {
  return (
    <Section title="Selo de segurança">
      <WrapperSeals>
        <div data-title="Selo de segurança">
          <img src="/security-seal.png" alt="Selo de segurança" />
        </div>
      </WrapperSeals>
    </Section>
  );
};
