import styled from 'styled-components';

export const WrapperSeals = styled.div`
  display: flex;
  align-items: center;
  line-height: 1em;
  gap: ${(p) => p.theme.spacing.small};

  > div {
    > img {
      object-fit: contain;
      width: 100%;
      max-width: 130px;
    }
  }

  & > div {
    display: flex;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
