import styled from 'styled-components';

export const LinkSocial = styled.a`
  display: inline-flex;
  align-items: center;

  color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.font.xLarge};

  padding: ${(p) => p.theme.spacing.nano};
  border-radius: ${(p) => p.theme.spacing.quark};

  transition: opacity 0.3s;

  &:hover {
    opacity: 0.75;
  }

  &.facebook {
    color: #fff;
    background-color: #4267b2;
  }

  &.twitter {
    color: #fff;
    background-color: #1da1f2;
  }

  &.youtube {
    color: #fff;
    background-color: #ff0000;
  }

  &.tiktok {
    position: relative;
    color: #fff;
    background-color: #161823;

    & svg {
      position: relative;
      z-index: 2;
    }

    & svg:first-child {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -55%);

      color: #23e8e2;
    }

    & svg:last-child {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-45%, -45%);

      color: #e21c4e;
    }
  }

  &.instagram {
    color: #fff;
    background: rgb(64, 93, 230);
    background: linear-gradient(
      200deg,
      rgba(64, 93, 230, 1) 5%,
      rgba(91, 81, 216, 1) 10%,
      rgba(131, 58, 180, 1) 20%,
      rgba(193, 53, 132, 1) 40%,
      rgba(225, 48, 108, 1) 50%,
      rgba(253, 29, 29, 1) 60%,
      rgba(245, 96, 64, 1) 70%,
      rgba(247, 119, 55, 1) 80%,
      rgba(252, 175, 69, 1) 90%,
      rgba(255, 220, 128, 1) 100%
    );
  }
`;

export const WrapperIcons = styled.div`
  display: flex;
  align-items: center;
  line-height: 1em;
  gap: ${(p) => p.theme.spacing.small};

  & > div {
    display: flex;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
