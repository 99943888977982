import type { FC } from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTiktok,
} from 'react-icons/fa';

import { ISocialMedias } from '@pra-vendas-themes/interfaces/ISocialMedias';

import { Section } from '../Section';
import { WrapperIcons, LinkSocial } from './styles';

export const SocialMedia: FC<ISocialMedias> = ({
  facebook,
  instagram,
  twitter,
  youtube,
  tiktok,
}) => {
  return (
    <Section title="Redes Sociais">
      <WrapperIcons>
        {facebook && (
          <div data-title="Facebook">
            <LinkSocial href={facebook} target="_blank" className="facebook">
              <FaFacebookF />
            </LinkSocial>
          </div>
        )}

        {instagram && (
          <div data-title="Instagram">
            <LinkSocial href={instagram} target="_blank" className="instagram">
              <FaInstagram />
            </LinkSocial>
          </div>
        )}

        {twitter && (
          <div data-title="Twitter">
            <LinkSocial href={twitter} target="_blank" className="twitter">
              <FaTwitter />
            </LinkSocial>
          </div>
        )}

        {youtube && (
          <div data-title="Youtube">
            <LinkSocial href={youtube} target="_blank" className="youtube">
              <FaYoutube />
            </LinkSocial>
          </div>
        )}

        {tiktok && (
          <div data-title="TikTok">
            <LinkSocial href={tiktok} target="_blank" className="tiktok">
              <FaTiktok />
              <FaTiktok />
              <FaTiktok />
            </LinkSocial>
          </div>
        )}
      </WrapperIcons>
    </Section>
  );
};
