import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';

import Button from '@pra-vendas-themes/default/components/Button';
import Input from '@pra-vendas-themes/default/components/Form/Input';
import { INewsletter } from '@pra-vendas-themes/interfaces/INewsletter';

import { Wrapper, WrapperForm, Title, Description } from './styles';

export const Newsletter: FC<INewsletter> = ({
  title,
  description,
  placeholder,
  onSend,
}) => {
  const [email, setEmail] = useState('');

  function handleChangeEmail(e: ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    onSend?.(email);
    setEmail('');
  }

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}

      {onSend && (
        <WrapperForm onSubmit={handleSubmit}>
          <Input
            name="email"
            placeholder={placeholder || 'Digite seu endereço de e-mail'}
            value={email}
            onChange={handleChangeEmail}
          />
          <Button size="xSmall" type="submit">
            <BsChevronRight />
          </Button>
        </WrapperForm>
      )}
    </Wrapper>
  );
};
