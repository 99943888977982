import type { FC } from 'react';

import NextLink from 'next/link';

import { IPage } from 'types/IPage';

import { StyledLink } from '../../styles';
import { Section } from '../Section';

type TPages = {
  pages: IPage[];
};

export const Pages: FC<TPages> = ({ pages }) => {
  return (
    <Section title="Páginas">
      {pages.map((page) => {
        if (page.type === 'url' && !!page.url) {
          return (
            <StyledLink key={page._id} href={page.url} target="_blank">
              {page.name}
            </StyledLink>
          );
        }
        if (page.type === 'html' && !!page.tag) {
          return (
            <NextLink key={page._id} href={`/paginas/${page.tag}`}>
              <StyledLink as="span">{page.name}</StyledLink>
            </NextLink>
          );
        }

        return null;
      })}
    </Section>
  );
};
