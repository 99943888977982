export const formatCNPJ = (value: string): string => {
  if (!value) return value;

  const onlyNums = value.replace(/\D/g, '');

  return onlyNums.replace(
    /(\d{1,2})(\d{1,3})(\d{1,3})(\d{1,4})(\d{1,2})/g,
    '$1.$2.$3/$4-$5'
  );
};
