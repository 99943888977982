import styled from 'styled-components';

export const FlagsContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.small};
  max-width: 75%;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.colors.background};
    height: 25px;

    > img {
      object-fit: contain;
      object-position: center;
      width: auto;
      max-height: 100%;
    }
  }
`;
