import { WithChildren } from 'types/WithChildren';
import type { TSection } from './types';

import { Wrapper, Title } from './styles';

export const Section: WithChildren<TSection> = ({ title, children }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};
