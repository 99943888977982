import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.spacing.xxLarge} 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  text-align: center;
  font-size: ${(p) => p.theme.font.small};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(p) => p.theme.spacing.medium};
`;

export const Title = styled.h2`
  text-transform: uppercase;
  font-size: ${(p) => p.theme.font.xLarge};

  @media (max-width: 768px) {
  }
`;

export const Description = styled.p`
  max-width: 50%;
  line-height: ${(p) => p.theme.spacing.large};
  color: ${(p) => p.theme.colors.textSecondary};
  font-weight: 300;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const WrapperForm = styled.form`
  border-radius: ${(p) => p.theme.borderRadius};
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 550px;

  & input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  & button {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    aspect-ratio: 1 / 1;

    svg {
      stroke-width: 0.5px;
    }
  }
`;
